<template>
  <div>
    <Banner
      v-if="flagShowGpOutageBanner"
      id="mobile-maintenance-banner"
      class="c-banner--alert"
      role="alert"
    >
      <span class="font-bold block">THE USPS® CHANGE OF ADDRESS WEBSITE IS TEMPORARILY UNAVAILABLE.</span>
      We are currently unable to service your online Change-of-Address. We apologize for the inconvenience, please try again later.
    </Banner>
    <Banner
      v-if="!!upcomingMaintenance"
      class="c-banner--alert"
      role="alert"
    >
      {{ upcomingMaintenance }}
    </Banner>
    <main
      class="home-mobile u-container"
    >
      <AppLoader
        v-if="loading"
        modal
      />
      <header class="home-mobile__header">
        <h1
          id="disputes-heading"
          class="font-bold text-2xl"
        >
          <template v-if="flagCoacExtendedFwdEnabled">
            View, Edit, Cancel or Extend Your Change-of-Address Order
          </template>
          <template v-else>
            View, Edit or Cancel Your Change-of-Address Order
          </template>
        </h1>
        <p class="mt-3">
          <template v-if="flagCoacExtendedFwdEnabled">
            If you want to view, edit, cancel or extend a Change-of-Address order you already submitted (whether at a Post Office™ or online), fill in the information below and click Get Started.
          </template>
          <template v-else>
            If you want to view, edit or cancel a Change-of-Address order you already submitted (whether at a Post Office™ or online), fill in the information below and click Get Started.
          </template>
        </p>
      </header>
      <div class="c-hairline mt-6" />
      <form @submit.prevent="validate">
        <section class="mt-6">
          <h2 class="font-bold text-xl">
            Enter your confirmation code and new ZIP Code™ to get started
          </h2>
          <p
            v-if="error"
            id="alert-login-error"
            class="py-2 text-sm text-red-200 font-bold"
          >
            {{ error }}
          </p>
          <Tooltip class="mt-3">
            <template slot="tip">
              <p class="font-bold">
                How to find your Confirmation Code:
              </p>
              <p class="mt-3">
                If you submitted your form online, the code is included in your confirmation email.
              </p>
              <p class="mt-3">
                If you submitted your form at a Post Office or online, the code is printed in the Change-of-Address order confirmation sent to your new address.
              </p>
            </template>
            <template slot="accessible">
              How to find your Confirmation Code description
            </template>
          </Tooltip>
          <InputCFPSID
            id="cfpsid"
            v-model="cfpsid"
            label="Confirmation Code"
            placeholder="0000-0000-0000-0000"
            name="confirmation-code"
            class="mt-3"
            :has-error="errors.cfpsid.hasError"
          >
            <template #helper>
              {{ errors.cfpsid.message }}
            </template>
          </InputCFPSID>
          <InputZipCode
            v-model="zipcode"
            label="New ZIP Code™"
            placeholder="00000"
            name="new-zip-code"
            class="mt-3"
            :has-error="errors.zipcode.hasError"
          >
            <template #helper>
              {{ errors.zipcode.message }}
            </template>
          </InputZipCode>
          <p class="mt-5">
            Haven't changed your address yet?
          </p>
          <a
            :href="`${$options.mgoUrl}/`"
            class="c-link"
          >
            Submit a Change-of-Address order online.
          </a>
          <!-- Show Disputes Link if enabled -->
          <template v-if="flagCoaDisputesEnabled">
            <p class="mt-3">
              Received a Change-of-Address confirmation in your name but not moving?
            </p>
            <router-link
              :to="{ name: 'disputes' }"
              class="c-link"
            >
              File a dispute online.
            </router-link>
          </template>
          <div class="mt-5 mb-10">
            <a
              href="https://about.usps.com/who/legal/privacy-policy/full-privacy-policy.htm"
              target="_blank"
              rel="noopener noreferrer"
              class="c-link mr-5"
            >
              Privacy Policy
            </a>
            <a
              :href="`${$options.mgoUrl}/mgo/terms-of-use`"
              target="_blank"
              rel="noopener noreferrer"
              class="c-link"
            >
              Terms of Use
            </a>
          </div>
        </section>
        <div class="c-navbar">
          <button
            id="get-started"
            ref="getStartedMgoc"
            class="c-btn"
          >
            Get Started
          </button>
        </div>
      </form>
    </main>
  </div>
</template>

<script>
import {
  AppLoader,
  Banner,
  InputCFPSID,
  InputZipCode,
  Tooltip,
} from '@mgo/ui/lib/components';
import homeMixin from './mixin';
import destinationManagerMixin from '../../mixins/destinationManager';

export default {
  name: 'Home',
  components: {
    AppLoader,
    Banner,
    InputCFPSID,
    InputZipCode,
    Tooltip,
  },
  mixins: [homeMixin, destinationManagerMixin],
};
</script>
