<template>
  <div>
    <Banner
      v-if="flagShowGpOutageBanner"
      id="desktop-maintenance-banner"
      class="c-banner--alert"
      role="alert"
    >
      <span class="font-bold block">THE USPS® CHANGE OF ADDRESS WEBSITE IS TEMPORARILY UNAVAILABLE.</span>
      We are currently unable to service your online Change-of-Address. We apologize for the inconvenience, please try again later.
    </Banner>
    <Banner
      v-if="!!upcomingMaintenance"
      class="c-banner--alert"
      role="alert"
    >
      {{ upcomingMaintenance }}
    </Banner>
    <main class="home-desktop u-container">
      <AppLoader
        v-if="loading"
        modal
      />
      <header class="home-desktop__header">
        <h1
          id="home-heading"
          class="font-bold"
        >
          <template v-if="flagCoacExtendedFwdEnabled">
            View, Edit, Cancel or Extend Your Change-of-Address Order
          </template>
          <template v-else>
            View, Edit or Cancel Your Change-of-Address Order
          </template>
        </h1>
        <p>
          <template v-if="flagCoacExtendedFwdEnabled">
            If you want to view, edit, cancel or extend a Change-of-Address order you already submitted (whether at a Post Office™ or online), fill in the information below and click Get Started.
          </template>
          <template v-else>
            If you want to view, edit or cancel a Change-of-Address order you already submitted (whether at a Post Office™ or online), fill in the information below and click Get Started.
          </template>
        </p>
      </header>
      <div class="c-hairline mt-6" />
      <form
        ref="form"
        @submit.prevent="validate"
      >
        <section class="flex my-8">
          <div class="w-1/2 pr-xl">
            <h2 class="text-2xl font-bold">
              Enter your confirmation code and new ZIP Code™ to get started
            </h2>
            <p
              v-if="error"
              id="alert-login-error"
              class="text-sm text-red-200 font-bold mt-2"
            >
              {{ error }}
            </p>
            <InputCFPSID
              id="cfpsid"
              ref="cfpsid"
              v-model="cfpsid"
              label="Confirmation Code"
              placeholder="0000-0000-0000-0000"
              name="confirmation-code"
              class="mt-3"
              :has-error="errors.cfpsid.hasError"
            >
              <template #helper>
                {{ errors.cfpsid.message }}
              </template>
            </InputCFPSID>
            <InputZipCode
              ref="zipcode"
              v-model="zipcode"
              label="New ZIP Code™"
              placeholder="00000"
              name="new-zip-code"
              class="mt-3"
              :has-error="errors.zipcode.hasError"
            >
              <template #helper>
                {{ errors.zipcode.message }}
              </template>
            </InputZipCode>
          </div>
          <div class="w-1/2 max:pl-xl">
            <h3 class="font-bold">
              More Information
            </h3>
            <h4 class="mt-3 text-lg">
              <strong>How to find your Confirmation Code</strong>
            </h4>
            <p>If you submitted your form online, the code is included in your confirmation email or printed in the Change-of-Address order confirmation sent to your new address.</p>
            <p class="mt-3">
              If you submitted your form at a Post Office or online, the code is printed in the Change-of-Address order confirmation sent to your new address.
            </p>
          </div>
        </section>
        <div class="c-hairline mt-6" />
        <section class="flex mt-8">
          <div class="w-1/2">
            <button
              id="get-started"
              ref="submit"
              class="c-btn"
            >
              Get Started
            </button>
            <p class="mt-5">
              Haven't changed your address yet?
            </p>
            <a
              :href="`${$options.mgoUrl}/`"
              class="c-link"
            >
              Submit a Change-of-Address order online.
            </a>
            <!-- Show Disputes Link if enabled -->
            <template v-if="flagCoaDisputesEnabled">
              <p class="mt-5">
                Received a Change-of-Address confirmation in your name but not moving?
              </p>
              <router-link
                :to="{ name: 'disputes' }"
                class="c-link"
              >
                File a dispute online.
              </router-link>
            </template>
          </div>
          <div class="w-1/2 text-right">
            <a
              href="https://about.usps.com/who/legal/privacy-policy/full-privacy-policy.htm#"
              target="_blank"
              rel="noopener noreferrer"
              class="c-link mr-5"
            >Privacy Policy</a>
            <a
              :href="`${$options.mgoUrl}/mgo/terms-of-use`"
              target="_blank"
              rel="noopener noreferrer"
              class="c-link"
            >Terms of Use</a>
          </div>
        </section>
      </form>
    </main>
  </div>
</template>

<script>
import {
  AppLoader,
  Banner,
  InputCFPSID,
  InputZipCode,
} from '@mgo/ui/lib/components';
import homeMixin from './mixin';
import destinationManagerMixin from '../../mixins/destinationManager';

export default {
  name: 'Home',
  components: {
    AppLoader,
    Banner,
    InputCFPSID,
    InputZipCode,
  },
  mixins: [homeMixin, destinationManagerMixin],
};
</script>
